import React, { useRef, useEffect } from "react";
import "../Cursor.css";


const Cursor = () => {
    const cursorRef = useRef(null);

    useEffect(() => {
        const moveCursor = (e) => {
            if (cursorRef.current) {
                cursorRef.current.style.top = `${e.clientY}px`;
                cursorRef.current.style.left = `${e.clientX}px`;
            }
        };

        document.addEventListener("mousemove", moveCursor);

        return () => {
            document.removeEventListener("mousemove", moveCursor);
        };
    }, []);

    return <div className="cursor" ref={cursorRef}></div>;
};

export default Cursor;
