import React, { useState, useEffect } from 'react';


const Navigation = () => {
    const [activeLink, setActiveLink] = useState('about');

    useEffect(() => {
        const handleScroll = () => {
            const aboutSection = document.getElementById('about');
            const skillsSection = document.getElementById('skills');
            const projectsSection = document.getElementById('projects');
            
            const aboutPosition = aboutSection.getBoundingClientRect().top;
            const skillsPosition = skillsSection.getBoundingClientRect().top;
            const projectsPosition = projectsSection.getBoundingClientRect().top;

            if (aboutPosition < window.innerHeight / 2 && skillsPosition > window.innerHeight / 2) {
                setActiveLink('about');
            } else if (skillsPosition < window.innerHeight / 2 && projectsPosition > window.innerHeight / 2) {
                setActiveLink('skills');
            } else if (projectsPosition < window.innerHeight / 2) {
                setActiveLink('projects');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
        <div className="links">
            <a
                href="#about"
                onClick={() => handleLinkClick('about')}
                style={{
                    color: activeLink === 'about' ? '#E2E8F0' : '#ffffff88',
                    fontWeight: activeLink === 'about' ? 'bold' : 'normal',
                }}
            >
                About
            </a>
            <a
                href="#skills"
                onClick={() => handleLinkClick('skills')}
                style={{
                    color: activeLink === 'skills' ? '#E2E8F0' : '#ffffff88',
                    fontWeight: activeLink === 'skills' ? 'bold' : 'normal',
                }}
            >
                Skills
            </a>
            <a
                href="#projects"
                onClick={() => handleLinkClick('projects')}
                style={{
                    color: activeLink === 'projects' ? '#E2E8F0' : '#ffffff88',
                    fontWeight: activeLink === 'projects' ? 'bold' : 'normal',
                }}
            >
                Projects
            </a>
        </div>
    );
};


export default Navigation;
