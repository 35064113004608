import Navigation from './links';
import Socials from './socials.js';
import Experience from './experience';
import Projects from './projects';
import Footer from './footer';


function Main() {
    return (
        <div className="main">
            <div className="leftside">
                <h1>Salomov Suxrob</h1>
                <h3>Fullstack Developer</h3>
                <p>I build pixel-perfect, engaging, and accessible digital experiences.</p>
                <Navigation />
                <Socials />
            </div>

            <div className="rightside" id='about'>
                <h2 className='r_about'>About</h2>
                <p> 
                    In 2016, I took my first steps into the world of programming and quickly realized that it was more than just a hobby—it was something I wanted to pursue professionally. Since then, I have come a long way, developing into a full-stack developer capable of creating complex and diverse projects. I have mastered languages like <b>Python, JavaScript,</b> and <b>PHP</b>, which allow me to easily switch between Frontend and Backend tasks, working with databases such as <b>PostgreSQL, SQLite3,</b> and <b>MySQL.</b>
                    <br/><br/>
                    One of my favorite areas is creating Telegram bots. Using the <b>Aiogram</b> framework, I have developed several bots that successfully perform tasks ranging from simple notifications to complex functions requiring integration with external APIs.
                    <br/><br/>
                    In web development, I value both aesthetics and functionality. I not only create a beautiful and user-friendly <b>Frontend</b> but also ensure <b>Backend</b> stability and performance, making my projects complete solutions for clients. Additionally, I work on design to ensure each project looks professional and appealing.
                    <br/><br/>
                    I also share my knowledge with others by running a <b><a href='https://t.me/Haker4ik' className='tglink' target="_blank" rel="noopener noreferrer">Telegram channel</a></b> where I discuss programming, share valuable resources, and offer ideas. I’m always working on improving my skills and staying up-to-date with the latest technologies to be as valuable as possible as a developer and mentor.
                    <br/><br/>
                    <b>P.S:</b> I began learning programming in 2016, but there were some pauses along the way. These breaks only strengthened my resolve to dive deeper and grow in this field.
                </p>

                <Experience />
                <Projects />
                <Footer />
            </div>
        </div>
    );
}


export default Main;
