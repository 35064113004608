

function Footer() {
    return (
        <div className="footer">
            <p>Copyright © 2024 - All Right Reserved. by <b>Hacker4ik</b></p>
            <a href="https://hacker4ik.uz/old" rel="noopener noreferrer">My old Portfolio</a>
        </div>
    );
}

export default Footer