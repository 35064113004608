import React, { useEffect, useState } from "react";


function Projects() {
    const [projectData, setProjectData] = useState([]);

    useEffect(() => {
        fetch("https://hacker4ik.uz/the_projects.php")
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setProjectData(data);
            })
            .catch(error => {
                console.error("Error fetching project data:", error);
            });
    }, []);

    return (
        <div className="projects">
            <h2>Projects</h2>
            <div className="myprojects" id="projects">
                {projectData.map((project, index) => (
                    <div className="pr_one" key={index}>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                            <img src={project.imgSrc} alt={project.title} />
                        </a>
                        <div className="info">
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <h3>{project.title}</h3>
                            </a>
                            <p>{project.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="linkprojects">
                <a href="https://t.me/Bots_of_TG" target="_blank" rel="noopener noreferrer">View all projects</a>
            </div>
        </div>
    );
}

export default Projects;
