import './App.css';
import Cursor from './components/cursor';
import Main from './components/main' 

function App() {
  return (
    <>
      <Cursor />
      <Main />
    </>
  );
}

export default App;
