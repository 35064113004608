import { FaPython, FaPhp, FaReact, FaGitAlt, FaHtml5, FaCss3Alt, FaSass } from "react-icons/fa";
import { SiJavascript, SiPostgresql, SiMysql, SiFastapi, SiTypescript } from "react-icons/si";
import { DiDjango } from "react-icons/di";


function Experience() {
    return (
        <div className="experience"  id="skills">
            <h2>Skills</h2>
            <div className="exp_icons">
                <FaHtml5 />
                <FaCss3Alt />
                <FaSass />
                <SiJavascript />
                <SiTypescript />
                <FaReact />
                <FaPython />
                <DiDjango />
                <SiFastapi />
                <SiPostgresql />
                <FaPhp />
                <SiMysql />
                <FaGitAlt />
            </div>
        </div>
    );
}


export default Experience