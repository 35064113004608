import { FaGithub, FaTelegram, FaInstagram } from "react-icons/fa";

function Socials() {
    return (
        <div className="socials">
            <a href="https://github.com/Hacker4ikUz" target="_blank"> <FaGithub /> </a>
            <a href="https://t.me/iSal1mov" target="_blank"> <FaTelegram /> </a>
            <a href="https://instagram.com/isal1mov" target="_blank"> <FaInstagram /> </a>
        </div>
    );
}


export default Socials